#loginSamlHint, .login_saml .privacyHint {
  border-left: solid 1px #808080;
  padding-left: 10px;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 0.8em;
  overflow: auto;
}

.login_saml {
  .privacyHint {
    border-left: solid 1px #808080;
    padding-left: 10px;
    margin-left: 20px;
    margin-top: 0;
    font-size: 0.8em;
    overflow: auto;
  }
  .btn {
    float: left;
    margin-right: 30px;
  }
}

.secondFactorAdderBody {
  .setFaField {
    margin-left: 30px;
    margin-bottom: 50px;
  }
  .captchaForm {
    margin-left: 30px;
    margin-bottom: 50px;
    margin-top: -20px;
  }
 }

.captchaHolder {
  input.form-control {
    display: inline-block;
    width: 200px;
  }
}

#usernamePasswordForm, #confirmAccountForm {
  @media (min-width: $screenMinWith) {
    width: 400px;
  }
}
#confirmAccountForm {
  .inputHolder {
    margin-bottom: 15px;
  }
  .saveResetRow {
    overflow: auto;
    .save {
      float: left;
    }
    .resend {
      float: right;
      button {
        font-weight: normal;
        font-size: 0.8em;
        font-style: italic;
      }
    }
  }
}

#conPwdForm {
  @media (min-width: $screenMinWith) {
    width: 400px;
  }

  .consultationName {
    margin-bottom: 30px;
  }
  .usernameLoginOpener button {
    margin-top: 20px;
    font-weight: normal;
  }
}

.loginUsername {
  .passwordRecovery {
    float: right;
    font-size: 0.8em;
  }
  #regConfirmation {
    font-size: 11px;
  }
}

.accountDeleteForm .submit {
  display: flex;
  width: 100%;

  label {
    font-weight: normal;
    flex-basis: 50%;
  }
  > div {
    flex-basis: 50%;
    text-align: right;
  }
}

.userAccountForm {
  .requestEmailChange {
    display: inline-block;
    margin-left: 10px;
    font-style: italic;
    font-size: 0.8em;
  }
  .changeRequested {
    display: block;
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .resendButton {
    color: $colorLinks;
    font-style: italic;
  }
  .btn2FaRemoveOpen {
    padding: 0;
    font-weight: normal;
    font-style: italic;
    font-size: 0.8em;
    margin-left: 16px;
  }
  .secondFactorRemoveBody {
    margin-top: 10px;
  }
}

.notificationForm {
  label {
    cursor: pointer;
  }
  input[type=checkbox] {
    margin-right: 10px;
  }
  .notificationRow {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .radioList {
    > div {
      padding-left: 27px;
    }
    label {
      display: block;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }
}

.userDataExport {
  .exportRow {
    margin-top: 20px;
    text-align: center;
  }
}

.askPermissionForm {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
