#sidebarSmallContent {
  font-size: 18px;
  > div {
    float: right;
  }
  h2 {
    font-size: 18px;
    margin: 0;
    padding: 4px;
  }
  ul {
    list-style-type: none;
    margin: 0 0 0 15px;
    padding: 0;
    > li {
      padding: 4px;
      margin: 0;
    }
  }
}

#sidebar {
  #sidebarSearch {
    .searchContent {
      text-align: center;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .nav-list {
    padding: 0;
    margin-bottom: 20px;

    > li {
      padding: 0 10px 10px 38px;
      a {
        color: $colorLinks;
        padding: 0;
        background: none;
        filter: none;
        margin: 0;
        text-shadow: none;
        display: inline;

        &:link, &:visited {
          color: $colorLinks;
        }

        .glyphicon, .fontello {
          position: absolute;
          left: -25px;
        }
      }
      &.nav-header {
        padding: 5px 0 5px 15px;
      }
      &.notifications {
        a span {
          font-size: 18px;
        }
      }
    }

    .nav-header {
      margin-bottom: 12px;
    }
  }

  .antragsgruenAd {
    margin-top: 30px;
    .content > div {
      text-align: center;
      margin-top: 15px;
    }
  }

  $createMotionButtonOffset: 10px;

  .createMotionHolder1 {
    position: absolute;
    top: 10px - $createMotionButtonOffset;
    right: -1px;
  }
  .createMotionHolder2 {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    transform: rotate(270deg) translateX(-100%);
    transform-origin: 0% 0%;

    display: inline-block;
    overflow: hidden;
    padding: 0 10px 10px;
  }
  .createMotion {
    background: linear-gradient(to bottom, $createMotionBtnColor 0%, lighten($createMotionBtnColor, 2.3%) 100%); /* W3C */
    padding: 10px 30px;
    margin-left: 15px;
    color: $createMotionTextColor;
    text-decoration: none;
    font-family: $headingFont;
    font-weight: bold;
    font-size: 14px;
    border-radius: 0 0 10px 10px;
    border: solid 1px lighten($createMotionBtnColor, 1.8%);
    transition: border ease-in-out 0.15s,background-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;

    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    display: inline-block;
    white-space: nowrap;

    > span {
      padding-right: 10px;
      font-size: 15px;
    }
    &:focus {
      border: solid 1px $focusBorderColor;
      background: linear-gradient(to bottom, lighten($createMotionBtnColor, 2.3%) 0%, lighten($createMotionBtnColor, 4.6%) 100%); /* W3C */
      box-shadow: 0 0 10px rgb(80, 136, 179);
    }
  }

  .search-query {
    width: 140px;
  }
}

ul.sidebarActions {
  @if $sidebarBackgroundGradient {
    @include linear-gradient(90deg, lighten($sidebarBackground, 2.3%), $sidebarBackground);
  } @else {
    background: $sidebarBackground;
  }
  padding: 7px 0 1px 21px;
  list-style-type: none;
  margin: -1px; // prevents white border
  position: relative;
  @if $contentBorderRadius > 0 {
    @include border-radius($contentBorderRadius);
  }
  @if $use-box-shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  }

  font-family: $sidebarActionFont;
  font-style: normal;
  font-weight: bold;

  li a, li > span, button {
    display: block;
    @if $sidebarUppercase {
      text-transform: uppercase;
    }
    font-size: 14px;
    line-height: 18px;
    color: $sidebarTextColor;
    padding-bottom: 10px;
    text-indent: -12px;
    margin: 7px 0 7px 15px;

    &:hover {
      color: darken($sidebarTextColor, 10%);
    }
  }

  .icon {
    color: $sidebarTextColor;
    margin-right: 10px;
  }

  .icon-placeholder {
    display: inline-block;
    width: 2px;
  }

  .fontello {
    margin-left: -10px;
  }

  .onlyAdmins {
    font-size: 0.7em;
  }
  .deactivated {
    font-style: italic;
  }
  .deactivatedMsg {
    font-size: 13px;
    color: #dbdbdb;
    text-transform: none;
  }
}
